import React, {useState, useEffect}                                             from 'react'
import './style.scss'


const Home = () => {

  return (<div className="home-page">
    HOME
  </div>)
}

export default Home