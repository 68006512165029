import React, {useState} from 'react';
import './style.scss';

const Footer = (props) => {
  return (
    <footer>
      FOOTER
    </footer>)
}

export default Footer